import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import {
  Section,
  SectionHeading,
} from '../components/Section';

import './404.scss';


const Page404 = () => (
  <Layout>
    <Helmet>
      <title>404</title>
      <meta
        name="description"
        content=""
      />
    </Helmet>

    <Section className="section-404 white-bg">
      <SectionHeading>
        404 page
      </SectionHeading>
    </Section>

  </Layout>
);

export default Page404;
